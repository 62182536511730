import React, { useState } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../components/Dropdown";
import UserContextProvider from "../../context/UserContext";
import { baseURL } from "../../components/constants";
const AdminDashboard = () => {
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);

  const initialValues = {
    title: "",
    description: "",
    
  };

  const changeHandler = (e) => {
    setVideos(e.target.files)
  }
  console.log(videos)

  // for(let i = 0; i<videos.length;i++) {
  //   console.log(videos[i].name)
  // }

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      // event.preventDefault();
      const { title, description } = values;
      // console.log(videos);

      try {
        const { data } = await axios.post(
          "https://mathsimplified-server.onrender.com/admin/modules",
          {
            title,
            description,
            videos,
          },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Module Successfully added!");
          navigate("/dashboard");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <UserContextProvider>
    <div className="w-full pb-80 m-auto bg-[#F5F5F5]  ">
    <div className="px-10 py-6 flex justify-between text-left">
        <div className="text-3xl font-semibold">
          <span className="text-[#084469]">Math</span>
          <span className="text-[#20A776]">Simplified</span>
        </div>
        <div className="flex">
          <div
            
            className="relative text-lg right-4 font-semibold cursor-pointer"
          >
            
            <Dropdown />
          </div>
          
        </div>
      </div>
      <div className="flex  justify-center items-center ">
        <form className="" action="/admin/modules" onSubmit={handleSubmit} method="POST" encType="multipart/form-data">
          <h1 className="mb-4  text-xl text-gray-800 font-semibold">
            Admin Dashboard
          </h1>

          <input
            className="b-[1px] w-full m-4 h-10 rounded-lg"
            type="text"
            name="title"
            placeholder="title"
            value={values.name}
            onChange={handleChange}
          ></input>
          <textarea
            className="w-full h-20 m-4 rounded-lg"
            type="text"
            name="description"
            placeholder="description"
            value={values.description}
            onChange={handleChange}
          ></textarea>
          {/* <input className='w-full h-10 m-4 rounded-lg' type='number' placeholder="no of videos"></input> */}
          <input
            className="w-full h-10 m-4 rounded-lg"
            multiple
            id="videos"
            name="videos"
            placeholder="Upload videos"
            type="file"
            accept=".mp4,.mkv,.avi"
            value={values.videos}
            onChange={changeHandler}
          ></input>

          <div className="text-center md:text-left">
            <button
              className="w-full py-2.5 my-4 font-semibold bg-[#084469] hover:bg-[#084490] px-4 py-2 text-white uppercase rounded text-xs tracking-wider"
              type="submit"
            >
              Upload
            </button>
          </div>
        </form>
      </div>
    </div>
    </UserContextProvider>
  );
};

export default AdminDashboard;
