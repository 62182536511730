import React from 'react'

const About = () => {
  return (
    <div>
      About Section
    </div>
  )
}

export default About
