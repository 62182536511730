import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import {Provider} from 'react-redux';
import {applyMiddleware,compose} from 'redux';
import thunk from 'redux-thunk'
import combineReducers from './reducers'
import {confgiureStore} from '@reduxjs/toolkit'
import UserContextProvider from './context/UserContext';
// import UserContextProvider from './context/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

// const store = confgiureStore({reducer:combineReducers})

root.render(
  <Auth0Provider
  domain="harshshekhawat.us.auth0.com"
  clientId="L9xTuX3eBfVkNI3ejhALWCWIeQigY6gP"
  authorizationParams={{
    redirect_uri: window.location.origin
  }}
>
  {/* <Provider store={store}> */}
  {/* <UserContextProvider> */}
    <App />
  {/* </UserContextProvider> */}
  {/* </Provider> */}
</Auth0Provider>
  );
  
