import React, { useContext, useEffect, useState } from "react";
import Card from "../components/Modules/Card";
import Module from '../components/Modules/Module'
import Dropdown from "../components/Dropdown";
import {UserContext} from '../context/UserContext'
import  UserContextProvider  from "../context/UserContext";
const Dashboard = () => {

  
 
  const [openModule, setOpenModule] = useState(false);
  

  return (
    <UserContextProvider>
    <div className=" bg-[#C5D9FF] px-10">
      <div className="px-10 py-6 flex justify-between text-left">
        <div className="text-3xl font-semibold">
          <span className="text-[#084469]">Math</span>
          <span className="text-[#20A776]">Simplified</span>
        </div>
        <div className="flex">
          <div
            
            className="relative text-lg right-4 font-semibold cursor-pointer"
          >
            {/* {user && (<p>{user.name}!</p>)} */}
            <Dropdown />
          </div>
          
        </div>
      </div>

      <div className="border-[1px] border-gray-500 shadow-lg h-[500px] scroll-smooth hover:scroll-auto overflow-scroll  p-10 grid  grid-cols-4 grid-flow-row gap-8">
          <Card setOpenModule={setOpenModule} />
      </div>

      <div className="p-10">
        {openModule && (
          <Module  />
        )}
      </div>
    </div>
     </UserContextProvider> 
  );
};

export default Dashboard;
