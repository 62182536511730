
const reducer = (user=[], action) => {
    switch (action.type) {
        case 'FETCH_USER':
            return user;
        default:
            return user;
        
    }
}

export default reducer