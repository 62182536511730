import React from "react";
import module2Img from "../../assets/Rectangle 4766(1).png";
import CommentSection from "./comments/CommentSection";
import CardData from "../../pages/Data/CardData";
// import CommentsContainer from "./comments/CommentContainer";

const Module = () => {

  const {i,title,duration, numOfVideo} = CardData;
  console.log(i, title);

  return (
    // CardData.map((card,i) => (
      <div className="p-5 bg-white shadow-xl rounded-md">
      <div className="m-10 flex  ">
        <img src={module2Img} alt="module2Img"></img>
        <div className="w-1/3 text-left mx-10">
          <h1 className="font-semibold text-xl">{title}</h1>
          <div>
            <p>{numOfVideo}</p>
            <p className="text-gray-800 py-2">{duration}</p>
          </div>
          <div className="text-gray-800 py-4">
            Lorem ipsum dolor sit amet. Sit voluptatum adipisci qui molestias
            nemo aut velit internos aut dolore ducimus. Et sunt.Lorem ipsum
            dolor sit amet. Sit voluptatum adipisci olore ducimus. Et sunt.Lorem
            ipsum dolor sit amet. Sit voluptatum adipisci qui qui molestias nemo
            aut velit internos aut dolore ducimus. Et sunt.
          </div>
        </div>
      </div>
      <div>
        <div className="p-10">
          <h1 className=" text-gray-800 text-3xl relative font-semibold  text-left">
            Video Lectures ____________________
          </h1>
          <div className="flex  left-10 top-10 relative">
            <iframe
              title="default-vid"
              className=" my-10 outline-0 border-black"
              width="200"
              height="100"
              src="https://www.youtube.com/watch?v=sYxlegOvkmU"
              frameborder="200"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <div className="relative top-10 left-20 w-1/3">
            aut velit internos aut dolore ducimus. Et sunt.Lorem ipsum
            dolor sit amet. Sit voluptatum adipisci olore ducimus
            </div>
          </div>
          <hr className="relative justify-items-center left-1 border-gray-400  top-10" />
          <div className="flex  left-10 top-10 relative">
            <iframe
              title="default-vid"
              className=" my-10 outline-0 border-black"
              width="200"
              height="100"
              src="https://www.youtube.com/watch?v=sYxlegOvkmU"
              frameborder="200"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <div className="relative top-10 left-20 w-1/3">
            aut velit internos aut dolore ducimus. Et sunt.Lorem ipsum
            dolor sit amet. Sit voluptatum adipisci olore ducimus
            </div>
          </div>
          <hr className="relative justify-items-center left-1 border-gray-400  top-10" />

          <div className="flex  left-10 top-10 relative">
            <iframe
              title="default-vid"
              className=" my-10 outline-0 border-black"
              width="200"
              height="100"
              src="https://www.youtube.com/watch?v=sYxlegOvkmU"
              frameborder="200"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <div className="relative top-10 left-20 w-1/3">
            aut velit internos aut dolore ducimus. Et sunt.Lorem ipsum
            dolor sit amet. Sit voluptatum adipisci olore ducimus
            </div>

          </div>
          <hr className="relative justify-items-center left-1 border-gray-400  top-10" />

          <div className="flex  left-10 top-10 relative">
            <iframe
              title="default-vid"
              className=" my-10 outline-0 border-black"
              width="200"
              height="100"
              src="https://www.youtube.com/watch?v=sYxlegOvkmU"
              frameborder="200"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <div className="relative top-10 left-20 w-1/3">
            aut velit internos aut dolore ducimus. Et sunt.Lorem ipsum
            dolor sit amet. Sit voluptatum adipisci olore ducimus
            </div>
          </div>
        </div>
      </div>
      <hr className="relative justify-items-center left-1 border-gray-400  top-10" />

      <div>
        <div>
        <h1 className="text-2xl text-left px-10  ">Post your doubts here</h1>
        </div>

        <div>
          <CommentSection  key={i} />
          {/* <CommentsContainer 
            comments={data?.comments}

             /> */}
        </div>
        
      </div>
    </div>
    // ))
  );
};

export default Module;
