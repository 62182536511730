import React, { useState, useEffect, createContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useFormik } from "formik";
import { updateSchema } from "../schemas";
import { baseURL } from "../components/constants";
const Profile = () => {
  const navigate = useNavigate();
//   const [user, setUser] = useState("");

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: updateSchema,
    onSubmit: async (values) => {
      const { email, password, confirmPassword } = values;
      // console.log("hi")
      
      try {
        await axios.post(
          "https://mathsimplified-server.onrender.com/updateprofile",
          {
            email,
            password,
            confirmPassword,
          },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
          );
          
          console.log("Hi");       
          navigate("/dashboard");
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <section className="max-h-screen h-screen flex flex-col md:flex-row bg-[#F5F5F5] justify-center space-y-10 md:space-y-0 md:space-x-16 items-center  mx-5 md:mx-0 md:my-0">

    <div className="md:w-1/3 shadow-xl -= rounded-xl m-auto bg-[#fff] max-w-sm w-32 p-10">
      <form onSubmit={handleSubmit} method="POST">
        <h1 className="mb-4 text-xl text-gray-800 font-semibold">Update Profile</h1>

        <input
          className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
          type="email"
          placeholder="Email Address"
          name="email"
          value={values.email}
          // onChange={event => setEmail(event.target.value)}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.email ? (
          <p className="text-red-700 text-xs">{errors.email}</p>
        ) : null}
        <input
          className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
          type="password"
          placeholder="New Password"
          name="password"
          value={values.password}
          // onChange={event => setPassword(event.target.value)}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.password ? (
          <p className="text-red-700 text-xs">{errors.password}</p>
        ) : null}
        {
          <input
            className="text-sm my-4 w-full px-4 py-2 border border-solid border-gray-300 rounded"
            type="password"
            name="confirmPassword"
            placeholder="Confirm New Password"
            value={values.confirmPassword}
            // onChange={event => setConfirmPassword(event.target.value)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        }
        {errors.confirmPassword ? (
          <p className="text-red-700 text-xs">{errors.confirmPassword}</p>
        ) : null}

        <div className="text-center md:text-left">
          <button
            className="w-full py-2.5 my-4  font-semibold bg-[#084469] hover:bg-blue-700 px-4 py-2 text-white uppercase rounded tracking-wider"
            type="submit"
          >
            Update
          </button>
        </div>

        
      </form>
    </div>
    </section>
  );
};

export default Profile;
