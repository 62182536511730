import axios from "axios";
import { createContext, useState, useEffect } from "react";
import { Toast } from "react-hot-toast";
// import axios from "axios";
export const UserContext = createContext({});

function UserContextProvider({ children }) {
 
  const [user, setUser] = useState(null);
  
  useEffect(() => {
    
    if(!user) {
      
          axios.get("https://mathsimplified-server.onrender.com/profile",{
          withCredentials:true,
          headers:{
            "Content-Type":"application/json"
          }
        })
        .then(({ data }) => {
          // console.log(data)
          setUser(data)
          
          
        })    
        
      
    }
    
  }, []); 

  return (
    <UserContext.Provider value={{user, setUser}}>
      {children}
    </UserContext.Provider> 
  );
}

export default UserContextProvider;
