import { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from './pages/Dashboard';
import About from "./components/About";
import Contact from "./components/Contact";
import SignUp from './pages/Signup';
import Signin from "./pages/Signin";
import Home from './pages/Home';
import Profile from "./pages/Profile";
import AdminDashboard from "./pages/Admin/AdminDashboard"
import {Toaster} from 'react-hot-toast'
// import UserContextProvider from "./context/UserContext";

function App() {

     
  return (
    <div className="App">
      <div>
      {/* <UserContextProvider> */}
        <BrowserRouter>
          <Toaster position="top-center" toastOptions={{duration:2000}} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/updateprofile" element={<Profile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/admin/modules" element={<AdminDashboard />} />
            <Route path="*" element={"No Page Found"} />
          </Routes>

        </BrowserRouter>
        {/* </UserContextProvider> */}
      </div>

      
    </div>
  );
}

export default App;
