import React, { useState, useEffect, createContext, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useFormik } from "formik";
import { signInSchema } from "../schemas";
import {toast} from "react-hot-toast";
import { UserContext } from "../context/UserContext";
import { baseURL } from "../components/constants";

function Signin() {

  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const initialValues = {
    email: "",
    password: "",
  };
  // const [email, setEmail] = useState("");
  // const [password,setPassword] = useState("");
  const adminCheck = () => {
    setIsAdmin(!isAdmin);
  };
  // console.log(isAdmin)
  // console.log(baseURL)

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: signInSchema,
    onSubmit: async (values) => {
      // event.preventDefault();
      const { email, password } = values;
      console.log(email)

      try {
        const {data} = await axios.post(
          "https://mathsimplified-server.onrender.com/login",
          {
            email,
            password,
            isAdmin
          },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // console.log("navigated");
        if(data.error) {
          toast.error(data.error)
        }
        else {
          if(!isAdmin) {
            navigate("/dashboard")
            toast.success("Login Successfull!")
          }
          else {
            
            navigate("/admin/modules");
            toast.success("Login Successfull!")
            
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const google = window.google;

  const handleCallbackResponse = (response) => {
    var userObject = jwt_decode(response.credential);
    setUser(userObject);

    // console.log(userObject);

    const { name, email, password } = userObject;

    axios
      .post("https://mathsimplified-server.onrender.com/login", {
        email,
        name,
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    navigate("/dashboard");

    // document.getElementById("google-btn").hidden = true;
  };

  useEffect(() => {
    google.accounts.id.initialize({
      client_id:
        "696472772014-bmp5ct0474g4ffrrcl9akk5h7io7l4o4.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });
    google.accounts.id.renderButton(document.getElementById("google-btn"), {
      theme: "outline",
      size: "large",
      width: "300",
    });
  }, []);

  return (
    <section className="max-h-screen h-screen flex flex-col md:flex-row bg-[#F5F5F5] justify-center space-y-10 md:space-y-0 md:space-x-16 items-center  mx-5 md:mx-0 md:my-0">
      <div className="md:w-1/3 shadow-xl rounded-xl m-auto bg-[#fff] max-w-sm w-32 p-10">
        <form onSubmit={handleSubmit} method="POST" action="/login">
          <h1 className="mb-4 text-xl text-gray-800 font-semibold">Sign In</h1>

          <input
            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
            type="email"
            placeholder="Email Address"
            name="email"
            value={values.email}
            // onChange={event => setEmail(event.target.value)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email ? (
            <p className="text-red-700 text-xs">{errors.email}</p>
          ) : null}
          <input
            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
            type="password"
            placeholder="Password"
            name="password"
            value={values.password}
            // onChange={event => setPassword(event.target.value)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.password ? (
            <p className="text-red-700 text-xs">{errors.password}</p>
          ) : null}

          <div className="mt-4 mb-8 flex justify-between font-medium text-sm">
              <div>
              <input
            className=" text-left"
            name="User Type"
            type="checkbox"
            value={values.isAdmin}
            onChange={adminCheck}
          />{" "}
          Admin

             
              
              {/* <span>{userType}</span> */}
              </div>
            <a
              className="text-[#084469] font-semibold hover:text-blue-700 hover:underline hover:underline-offset-4"
              href="#"
              alt="pass"
            >
              Forgot Password?
            </a>
          </div>
          <div className="text-center md:text-left">
            <button
              className="w-full py-2.5 my-4 font-semibold bg-[#084469] hover:bg-blue-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider"
              type="submit"
            >
              Sign In
            </button>
          </div>
          <div id="google-btn" onSubmit={handleCallbackResponse}></div>

          <div className="mt-4 font-medium text-sm text-sate-500 ">
            Don't have an account?
            <Link to="/signup">
              <p
                className=" cursor-pointer text-[#084469] font-semibold hover:underline hover:underline-offset-4"
                href="#"
              >
                SignUp
              </p>
            </Link>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Signin;
