import React, {
  useContext
} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

export default function Dropdown() {

  const context = useContext(UserContext);

  const {user,setUser} = context;
  console.log(user)
  const navigate = useNavigate();  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateProfileFunction = () => {
    navigate("/updateprofile");
  }

  const logoutBtn = () => {
    navigate("/");
  };

  return (
    <div className='relative right-10' >
      <button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='text-[#084469] text-xl'
        color='inherit'
        size='large'
      >
         {user && (<p>{user.name}</p>)}
      </button>
      <Menu
        className='w-[200px]'
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // MenuListProps={{
        //   'aria-labelledby': 'basic-button',
        // }}
      >
        <MenuItem onClick={updateProfileFunction} className=''>Update Profile</MenuItem>
        <MenuItem onClick={logoutBtn}>Logout</MenuItem>
      </Menu>
    </div>
  );
}