import React from "react";
import Header from "../components/Header";
import About from "../components/About";
import Contact from "../components/Contact";
import LandingPage from "./LandingPage";
import { Button } from "@material-tailwind/react";
import landingImg from "../assets/Group 12377.png";
import contactImg from '../assets/contact_leftImg.png';
import {BsInstagram,BsTwitter,BsYoutube, BsDiscord, BsFacebook} from 'react-icons/bs'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { useNavigate } from "react-router-dom";
const Home = () => {

  const navigate = useNavigate();


  return (
    <React.Fragment>
      <section>
        <Header />

        <div className="h-screen bg-[#F7F7F8] flex px-52 text-left">
          <div className="mt-32">
            <h1 className="text-[#084469] text-5xl font-semibold text-left">
            Don't let math intimidate {" "}
              <span className="text-[#20A776]">
                you any longer. <br />
              </span>
            </h1>
            <span className="relative top-5 text-[#084469] font-semibold text-3xl">
              Start Your Journey Today
              </span>
            <div>
              <p className="text-[#485D6F] text-lg my-10">
              At math simplified, we specialize in providing simplified explanations to help you grasp even the most challenging topics.
              </p>
            </div>
            <div className="my-12">
              <Button className="w-[200px] outline outline-2 outline-[#084469] bg-[#084469] hover:shadow-none hover:bg-white hover:text-[#084469] hover:outline">
                Trial for free
              </Button>
            </div>
          </div>
          <div>
            <img
              className="my-20 mx-14 relative right-12"
              src={landingImg}
              alt="landingImg"
            ></img>
          </div>
        </div>

      </section>

      <section>
        <div className="px-40 py-20">
          <div className="">
            <h1 className="text-[#084469] text-4xl font-semibold">
              Courses we provide
            </h1>
          </div>
          <div className="border-4 border-x-0 border-y-[#20A776] shadow-xl my-20 px-10 w-full grid grid-row-1 grid-flow-col rounded-[20px] h-[200px] bg-gray-200">
            <div className="m-auto text-lg">Video 1</div>
            <div className="m-auto text-lg">Video 2</div>
            <div className="m-auto text-lg">Video 3</div>
            <div className="m-auto text-lg">Video 4</div>
          </div>
          <div className="m-20">
            {/* <Button  className="w-[200px] outline outline-2 outline-[#084469] bg-[#084469] hover:shadow-none hover:bg-white hover:text-[#084469] hover:outline">
              Enroll for Free {">>"}
            </Button> */}
          </div>
        </div>
      </section>
      <div className="">
        <div className="pb-10">
          <h1 className="text-[#084469] text-4xl font-semibold">About Us</h1>
        </div>
        <div className="mx-60">
          <h3 className="mx-32 text-2xl font-semibold">
          Math Simplified encompasses a wide spectrum of mathematical subjects, providing a thorough coverage of diverse mathematical topics.
          </h3>
          <p className="text-[#485D6F] my-10 font-medium text-lg">
          Math Simplified is a platform dedicated to breaking down complex mathematical concepts into easily understandable explanations, making math accessible and enjoyable for everyone. Our aim is to provide simplified explanations that enable individuals to grasp the fundamentals with ease. We cover a wide range of mathematical topics, from basic arithmetic to advanced modern mathematics, leaving no concept unexplored. With Math Simplified, you can find explanations for any mathematical concept you come across, ensuring a comprehensive understanding of the subject matter.
          </p>
          <p className="text-[#485D6F] my-10 font-medium text-md text-lg">
            
          Embark on your mathematical journey today with Math Simplified and acquire the confidence to tackle any math problem you encounter. Immerse yourself in our extensive collection of resources and let Math Simplified become your reliable companion on this journey. Together, let's simplify math, making it accessible, comprehensible, and enjoyable for individuals of all backgrounds.
          </p>
        </div>
      </div>
      <section>
        <div className="py-20 px-40">
          <div className="m-10">
            <h1 className="text-[#084469] text-4xl font-semibold">Contact Us</h1>
          </div>
          <div className="flex">
            <div className="text-left w-1/2">
              <h1 className="text-xl">MAXIMIZE YOUR SKILLS</h1>
              <div className="text-[#084469] my-3 text-4xl font-semibold">
                  HOW TO REACH US <br></br> <span className="text-[#084469] my-2">Math</span><span className="text-[#20A776] my-2">Simplified</span>
              </div>
              <div>
                  <p className="text-[#485D6F] mt-6 font-medium text-md text-lg">
                  We value your feedback and are here to assist you. If you have any questions, suggestions, or concerns, please feel free to reach out to us. Our team is dedicated to providing the best possible support to our users.
                  </p>
                  {/* <p className="text-[#485D6F] font-medium text-md text-lg">repellendus aut perspiciatis necessitatibus. Sed neque necessitatibus aut eligendi corporis aut.</p> */}
              </div>
              <div className="flex my-2 ">
                <BsInstagram className="text-xl text-[#084469] my-4 mr-2 cursor-pointer"/>
                <BsYoutube className="text-xl text-[#084469] my-4 mx-3 cursor-pointer" />
                {/* <BsTwitter className="text-xl text-[#084469] my-4 mx-3 cursor-pointer" /> */}
                {/* <BsDiscord className="text-xl text-[#084469] my-4 mx-3 cursor-pointer"/> */}
                <BsFacebook className="text-xl text-[#084469] my-4 mx-3 cursor-pointer" />
              </div>
              <div>
                <Button className="w-[200px] my-5 outline outline-2 outline-[#084469] bg-[#084469] hover:shadow-none hover:bg-white hover:text-[#084469] hover:outline">Connect with Us</Button>
              </div>
            </div>
            
            <div>
              <img className="relative left-20" src={contactImg} alt="contactImg"></img>
            </div>
          </div>  
        </div>
      </section>
      <footer>
        <div className="bg-[#084469] pt-10 px-40">
            <div className="text-2xl font-semibold">
              <span className="text-white">Math</span><span className="text-[#20A776]">Simplified</span>
            </div>
            <div>
              <h1 className="text-white py-10 text-4xl font-semibold">Get started with our team</h1>
            </div>
            <Button className="w-[200px] text-white my-5 outline outline-2 outline-white bg-transparent hover:shadow-none hover:bg-white hover:text-[#084469] hover:outline">Request a Demo </Button>

            <div className="text-white p-2">
              Copyright MathSimplified © 2023 All Rights Reserved
            </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Home;
