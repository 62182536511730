import React, { useState } from "react";
import moduleImg from "../../assets/Rectangle 4766.png";
import CardData from "../../pages/Data/CardData";

const Card = ({ setOpenModule }) => {

  // const {i, title } = CardData;

  const openModuleFunction = () => {
    // console.log(i)
    setOpenModule(true);
  };

  return CardData.map((card) => (
    <div>
      <div
        onClick={openModuleFunction}
        className="text-center px-10 pt-10 bg-white rounded-xl border-2 shadow-xl cursor-pointer"
      >
        <div>
          <img
            className="relative left-5"
            src={moduleImg}
            alt="moduleImg"
          ></img>
        </div>
        <div className="">
          <h1 className="text-xl font-semibold">{card.title}</h1>
          <p className="text-gray-800">{card.description}</p>
          <p className="mt-10 text-gray-500">{card.duration}</p>
        </div>
      </div>
    </div>
  ));
};

export default Card;
