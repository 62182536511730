import React, { useState, useEffect, createContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useFormik } from "formik";
import { signUpSchema } from "../schemas";
import { toast } from "react-hot-toast";
// import { baseURL } from "../components/constants";

function Signup({}) {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminKey, setAdminKey] = useState("");

  // const [data, setData] = useState({
  //   name:"",
  //   email:"",
  //   password:"",
  //   confirmPassword:""
  // });

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    // isAdmin:""
  };

  const adminCheck = () => {
    setIsAdmin(!isAdmin);

  };
  // console.log(isAdmin);

  const adminKeyFunction = (e) => {
    setAdminKey(e.target.value)
    // console.log(adminKey)
  }


  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema,
    onSubmit: async (values) => {
      // event.preventDefault();
      const { name, email, password, confirmPassword } = values;

      try {
        const {data} = await axios.post(
          "https://mathsimplified-server.onrender.com/register",
          {
            name,
            email,
            password,
            confirmPassword,
            isAdmin,
            adminKey
          },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if(data.error) {
          toast.error(data.error)
        }
        else {
          toast.success("Registraion Successful!")
          if(!isAdmin) {
            navigate("/dashboard");
  
          }
          else {
            navigate('/admin/modules');
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const google = window.google;

  const handleCallbackResponse = (response) => {
    var userObject = jwt_decode(response.credential);
    setUser(userObject);

    // console.log(userObject);

    const { name, email, password } = userObject;

    axios
      .post("https://mathsimplified-server.onrender.com/register", {
        email,
        name,
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    navigate("/dashboard");

    // document.getElementById("google-btn").hidden = true;
  };

  useEffect(() => {
    google.accounts.id.initialize({
      client_id:
        "696472772014-bmp5ct0474g4ffrrcl9akk5h7io7l4o4.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });
    google.accounts.id.renderButton(document.getElementById("google-btn"), {
      theme: "outline",
      size: "large",
      width: "300",
    });
  }, []);

  return (
    <section className="max-h-screen h-screen flex flex-col md:flex-row bg-[#F5F5F5] justify-center space-y-10 md:space-y-0 md:space-x-16 items-center  mx-5 md:mx-0 md:my-0">
      <div className="md:w-1/3 shadow-xl rounded-xl m-auto bg-[#fff] max-w-sm w-32 p-10">
        <form onSubmit={handleSubmit} method="POST">
          <h1 className="mb-4 text-xl text-gray-800 font-semibold">Sign Up</h1>
          <input
            className="text-sm w-full mb-4 px-4 py-2 border border-solid border-gray-300 rounded"
            type="text"
            name="name"
            placeholder="Name"
            value={values.name}
            // onChange={event => setName(event.target.value)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.name ? (
            <p className="text-red-700 text-xs">{errors.name}</p>
          ) : null}
          <input
            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
            type="email"
            placeholder="Email Address"
            name="email"
            value={values.email}
            // onChange={event => setEmail(event.target.value)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email ? (
            <p className="text-red-700 text-xs">{errors.email}</p>
          ) : null}
          <input
            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
            type="password"
            placeholder="Password"
            name="password"
            value={values.password}
            // onChange={event => setPassword(event.target.value)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.password ? (
            <p className="text-red-700 text-xs">{errors.password}</p>
          ) : null}
          {
            <input
              className="text-sm my-4 w-full px-4 py-2 border border-solid border-gray-300 rounded"
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={values.confirmPassword}
              // onChange={event => setConfirmPassword(event.target.value)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          {errors.confirmPassword ? (
            <p className="text-red-700 text-xs">{errors.confirmPassword}</p>
          ) : null}
          {isAdmin && (
            <input 
            className="text-sm my-2 w-full px-4 py-2 border border-solid border-gray-300 rounded"
            placeholder="Enter the admin key"
            type="number"
            name="adminKey"
            value={values.adminKey}
            onChange={adminKeyFunction}

            required
            ></input>
          )}
          <input
            className=" text-left"
            name="User Type"
            type="checkbox"
            value={values.isAdmin}
            onChange={adminCheck}
          />{" "}
          Admin
          <div className="text-center md:text-left">
            <button
              className="w-full py-2.5 my-4 font-semibold bg-[#084469] hover:bg-blue-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider"
              type="submit"
            >
              Sign Up
            </button>
          </div>
          <div id="google-btn" onSubmit={handleCallbackResponse}></div>
          <div className="mt-4 font-medium text-sm text-sate-500 ">
            Already have an account?
            <Link to="/signin">
              <p
                className=" cursor-pointer text-[#084469] font-semibold hover:underline hover:underline-offset-4"
                href="#"
              >
                Sign In
              </p>
            </Link>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Signup;
