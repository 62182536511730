import React, { useState } from "react";

import axios from "axios";
import { User } from "@auth0/auth0-react";
import { baseURL } from "../../constants";
const CommentSection = () => {

    const [comment, setComment] = useState('');

    // const commentData = {
    //   content:comment,
      
    // }
    var currentdate = new Date();
    var currMonth = currentdate.getMonth() +1;
    var datetime = currentdate.getDate() + "/" + currMonth
    + "/" + currentdate.getFullYear() + " " 
    + currentdate.getHours() + ":" 
    + currentdate.getMinutes() + ":" + currentdate.getSeconds();
  
    const handleChange = (e) => {

        setComment(e.target.value);
        // console.log(comment);
    }

    const submitHandler = async (e) => {
      e.preventDefault();
      
      try {
            
        await axios.post(
          `${baseURL}/dashboard/comments`,
          {
            // name,
            comment,
            datetime
          },
          {
            withCredentials: true,
            headers:{
              "Content-Type":"application/json"
            }
          }
        );
        console.log("comment sent")
        

      } catch (error) {
        console.log(error);
      }

    }

  return (
    <div>
      <form onSubmit={submitHandler} className="text-right">
        <textarea
          type="text"
          name="comment"
          className="w-full h-20 text-left my-5 placeholder:p-2 rounded-md border-[1px] border-gray-500"
          onChange={handleChange}
          placeholder="Type something here..."
        ></textarea>
        <button type="submit" className="bg-blue-800 text-white ho w-20 h-10 rounded-xl ">
          Post
        </button>
        
      </form>
      <div className="bg-white text-left px-10">
        <h1>Comments</h1>
            <div>
              <ul>
                <li>
                  <div className="border-[1px] border-gray-300 rounded-md p-2">
                    <h3 className="text-md text-bold">
                    {/* {user.name && "Anonymous"} */} Anonymous
                    </h3>
                    <p>How to solve exponential integration?</p>
                    <div>
                      <small>
                          {datetime}
                      </small>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
        </div>
    </div>
  );
};

export default CommentSection;

