import module2Img from "../../assets/Rectangle 4766(1).png";

const CardData = [
    {   key:1,
        title:"Statistics",
        description:"Statistics is the science concerned with developing and studying methods for collecting, analyzing, interpreting and presenting empirical data.",
        duration:"3Hrs and 45 mins",
        numOfVideo:12,

    },{
        key:2,
        title:"Trigono",
        description:"the branch of mathematics concerned with specific functions of angles and their application to calculations",
        duration:"2Hrs and 40 mins",
        numOfVideo:7,
    },{
        key:3,
        title:"Algebra",
        description:"Algebra is the branch of mathematics that helps in the representation of problems or situations in the form of mathematical expressions",
        duration:"3Hrs and 30 mins",
        numOfVideo:11,
    },{
        key:4,
        title:"Calculus",
        description:"a type of mathematics that deals with rates of change, for example the speed of a falling object",
        duration:"1Hrs and 00 mins",
        numOfVideo:5,
    },{
        key:5,
        title:"Differential",
        description:"The differential can be located in various areas, depending on what drive system the vehicle operates with. ",
        duration:"1Hrs and 30 mins",
        numOfVideo:9,
    },
]

export default CardData;